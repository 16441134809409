import { Icon } from "@iconify/react";
import React, { useState } from "react";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ModalImage from "react-modal-image";

export default function MasonryGallery({ color }) {
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(10);

  const portfolioData = [
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_3001.JPG",
      srcLg: "/images/tech-meet_2022/IMG_3001.JPG",
      category: "tech-meet_2022",
      height: 310,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_2903.JPG",
      srcLg: "/images/tech-meet_2022/IMG_2903.JPG",
      category: "tech-meet_2022",
      height: 389,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_2911.JPG",
      srcLg: "/images/tech-meet_2022/IMG_2911.JPG",
      category: "tech-meet_2022",
      height: 320,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_2938.JPG",
      srcLg: "/images/tech-meet_2022/IMG_2938.JPG",
      category: "tech-meet_2022",
      height: 500,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_2948.JPG",
      srcLg: "/images/tech-meet_2022/IMG_2948.JPG",
      category: "tech-meet_2022",
      height: 550,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_2956.JPG",
      srcLg: "/images/tech-meet_2022/IMG_2956.JPG",
      category: "tech-meet_2022",
      height: 400,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_2975.JPG",
      srcLg: "/images/tech-meet_2022/IMG_2975.JPG",
      category: "tech-meet_2022",
      height: 580,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_2989.JPG",
      srcLg: "/images/tech-meet_2022/IMG_2989.JPG",
      category: "tech-meet_2022",
      height: 320,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_3014.JPG",
      srcLg: "/images/tech-meet_2022/IMG_3014.JPG",
      category: "tech-meet_2022",
      height: 420,
    },
    {
      title: "Tech Meet-Up 2022",
      subtitle: "View Large",
      href: "/portfolio/portfolio-details",
      src: "/images/tech-meet_2022/IMG_3027.JPG",
      srcLg: "/images/tech-meet_2022/IMG_3027.JPG",
      category: "tech-meet_2022",
      height: 440,
    },
  ];
  // const categoryMenu = [
  //   {
  //     title: "Tech Meet-Up 2022",
  //     category: "tech-meet_2022",
  //   },
  // ];
  return (
    <>
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading
            color={color}
            title="Tech Meet-Up 2022"
            subtitle="Gallery"
          />
          {/* <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === "all" ? "active" : ""}>
                <span onClick={() => setActive("all")}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? "active" : ""}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div> */}
        </Div>
      </Div>
      <Spacing lg="90" md="45" />
      <Div className="cs-masonry_4_col">
        {portfolioData.slice(0, itemShow).map((item, index) => (
          <Div
            className={`${
              active === "all"
                ? ""
                : !(active === item.category)
                ? "d-none"
                : ""
            }`}
            key={index}
          >
            <Div
              className="cs-portfolio cs-style1 cs-type2"
              style={{ height: `${item.height}px` }}
            >
              <Div className="cs-lightbox_item">
                <ModalImage
                  small={item.src}
                  large={item.srcLg}
                  alt={item.title}
                />
              </Div>
              <Div className="cs-portfolio_hover" />
              <span className="cs-plus" />
              <Div
                className="cs-portfolio_bg cs-bg"
                style={{ backgroundImage: `url("${item.src}")` }}
              />
              <Div className="cs-portfolio_info">
                <Div className="cs-portfolio_info_bg cs-accent_bg" />
                <h2 className="cs-portfolio_title">{item.title}</h2>
                <Div className="cs-portfolio_subtitle">{item.subtitle}</Div>
              </Div>
            </Div>
          </Div>
        ))}
      </Div>
      <Div className="container">
        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 4)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
    </>
  );
}
