import React from "react";
import "./hero.scss";
import Div from "../Div";
import { Box, Typography, Button, Grid } from "@mui/material";

export default function Hero({
  image,
  title,
  subtitle,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
}) {
  // const handleRedirect = () => {
  //   window.location.href = "https://techmeet.betca.org/";
  // };

  const handleRedirect2 = () => {
    window.open("https://meetup.betca.org/register", "_blank");
  };

  const handleRedirect3 = () => {
    // Replace 'your-pdf-file.pdf' with the actual path of your PDF file in the public folder
    const pdfURL = process.env.PUBLIC_URL + "/patronage.pdf";
    window.open(pdfURL, "_blank");
  };
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1"
      style={{
        backgroundColor: "#add5fc",
        backgroundImage: "linear-gradient(180deg, #add5fc 0%, #eaf9f9 100%)",
      }}
      // style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          rowGap={2}
          sx={{ mt: -8 }}
        >
          <img src={image} alt="Logo.png" width={600} />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            rowSpacing={3}
            columnSpacing={2}
            sx={{ textAlign: "center" }}
          >
            {/* <Grid item xs={11} md={11} sm={11} lg={6}>
              <Typography variant="h5" color="black" sx={{ fontWeight: 600 }}>
                {parse(title)}
              </Typography>
              <Button
                size="medium"
                onClick={handleRedirect}
                variant="contained"
                color="primary"
                sx={{ borderRadius: "12px" }}
              >
                Register Now
              </Button>
            </Grid> */}
            <Grid item xs={11}>
              <Typography variant="h5" color="black" sx={{ fontWeight: 600 }}>
                Be a Patron of BeTCA
              </Typography>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Button
                size="large"
                onClick={handleRedirect2}
                variant="contained"
                color="primary"
                sx={{ borderRadius: "12px" }}
              >
                Express your Interest
              </Button>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Button
                size="large"
                onClick={handleRedirect3}
                variant="contained"
                color="primary"
                sx={{ borderRadius: "12px" }}
              >
                Patronage Details
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Div>
      {/* <Div className="cs-hero_info">
            <Div>
              <Button btnLink={btnLink} btnText={btnText} />
            </Div>
            <Div>
              <Div className="cs-hero_subtitle">{subtitle}</Div>
            </Div>
          </Div>
        </Div>*/}
      {/* <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />  */}
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
