import { createContext, useContext, useState } from "react";

const themeContext = {
  mode: false, // Set the initial value to false
};

const AppContext = createContext(themeContext);

const AppProvider = ({ children }) => {
  const [mode, setMode] = useState(
    localStorage.getItem("mode") === "true" || false
  );

  // Save the mode in localStorage whenever it's changed
  const updateMode = (value) => {
    setMode(value);
    localStorage.setItem("mode", value);
  };

  const contextValue = { mode, setMode: updateMode };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(AppContext);
};

export default AppProvider;
