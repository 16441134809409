import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Div from "../Div";

export default function SocialWidget() {
  const socialMediaHandles = [
    {
      link: "https://www.linkedin.com/company/betca/",
      icon: "fa6-brands:linkedin-in",
    },
    {
      link: "https://www.facebook.com/BelagaviITCA",
      icon: "fa6-brands:facebook",
    },
    {
      link: "https://www.instagram.com/betcaorg/",
      icon: "fa6-brands:instagram",
    },
    {
      link: "https://www.youtube.com/@BeTCAorg",
      icon: "fa6-brands:youtube",
    },
    {
      link: "https://x.com/BelagaviITCA",
      icon: "fa6-brands:x-twitter",
    },
  ];
  return (
    <Div className="cs-social_btns cs-style1">
      {socialMediaHandles.map((item, i) => (
        <Div key={i}>
          <Link to={item.link} target="_blank" className="cs-center">
            <Icon icon={item.icon} />
          </Link>
        </Div>
      ))}
    </Div>
  );
}
