export const colors = {
  white: "#fff",
  black: "#161616",
  primary: "#FEFEFE",
  secondary: "rgba($primary, 0.7)",
  ternary: "#999696",
  border: "#4D4D4D",
  gray: "#181818",
  accent: "#FF4A17",
};
