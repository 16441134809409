import React from "react";
import Div from "../Div";
import "./homeContainer.scss";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function HomeContainer({ variant, title, para, to }) {
  return (
    <Div className={variant ? `cs-funfact_wrap ${variant}` : "cs-funfact_wrap"}>
      <Div
        className="cs-funfact_shape"
        style={{ backgroundImage: "url(./images/funfact_shape_bg.svg)" }}
      />
      <Div className="cs-funfact_left">
        <Box>
          <img
            src="/images/logo/betca_logo.png"
            width={300}
            alt="Website_Logo"
          />
        </Box>
      </Div>
      <Div className="cs-funfact_right">
        <Div className="cs-funfact_heading">
          <h2>{title}</h2>
        </Div>
        <Div>
          <p style={{ textAlign: "justify" }}>{para}</p>
        </Div>
        <Div className="cs-home-link">
          <Link to={to}>Learn more</Link>
        </Div>
      </Div>
    </Div>
  );
}
