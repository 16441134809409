import React from "react";
import { Icon } from "@iconify/react";

export default function ContactInfoWidget({ withIcon, title, color }) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="material-symbols:add-call-rounded" />
            </span>
          ) : (
            ""
          )}
          <p style={{ color: color }}>+91 9353199149</p>
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="mdi:envelope" />
            </span>
          ) : (
            ""
          )}
          <a
            href="mailto: contact@betca.org"
            target="blank"
            style={{
              fontSize: "16px",
              color: color,
            }}
          >
            contact@betca.org
          </a>
        </li>
        {/* <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="mdi:map-marker" />
            </span>
          ) : (
            ""
          )}
          50 Wall Street Suite, 44150 <br />
          Ohio, United States
        </li> */}
      </ul>
    </>
  );
}
